<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            required
            default="today"
            type="year"
            label="년도"
            name="year"
            v-model="searchParam.year"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :isFlag="true"
            label="금월 미완료 설비"
            name="uncompleteFlag"
            v-model="searchParam.uncompleteFlag"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :isFlag="true"
            label="점검결과 표시"
            name="resultFlag"
            v-model="searchParam.resultFlag"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="소방 이슈점검 현황"
      :columns="gridColumns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="suffixTitle">
        <font style="font-size:0.8em;font-weight:300;">
          ✔️ : 점검완료 &nbsp;&nbsp;
          <q-icon name="edit" class="text-orange"></q-icon> : 진행중 &nbsp;&nbsp;
          ❌ : 미진행
        </font>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="!isFuture(props, col)">
          <template v-if="props.row[col.name]==='3'">
            ✔️
          </template>
          <template v-else-if="props.row[col.name]==='2'">
            <q-icon name="edit" class="text-orange"></q-icon>
          </template>
          <template v-else-if="props.row[col.name]==='1'">
            ❌
          </template>
          <template v-else-if="props.row[col.name]==='양호'">
            <q-chip outline color="teal" text-color="white" icon="done">
              {{props.row[col.name]}}
            </q-chip>
          </template>
          <template v-else-if="props.row[col.name]==='불량'">
            <q-chip outline color="red" text-color="white" icon="close">
              {{props.row[col.name]}}
            </q-chip>
          </template>
          <template v-else>
          </template>
        </template>
        <template v-else>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingIssueStatus',
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: '',
        uncompleteFlag: 'N',
        resultFlag: 'N',
      },
      year: '',
      grid: {
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridColumns() {
      let cols = [
        {
          name: 'plantName',
          field: 'plantName',
          label: 'LBLPLANT',
          align: 'center',
          sortable: true,
          style: 'width: 100px',
        },
        {
          name: 'fireFightingName',
          field: 'fireFightingName',
          label: '소방설비',
          align: 'left',
          sortable: true,
          style: 'width: 150px',
          type: 'link',
        },
        {
          name: 'sopFireFightingTypeName',
          field: 'sopFireFightingTypeName',
          label: 'LBLTYPE',
          align: 'center',
          sortable: true,
          style: 'width: 100px',
        },
      ];
      let resultCol = []
      for (let month = 1; month <= 12; month++) {
        resultCol.push({
          name: `month${month}`,
          field: `month${month}`,
          label: `${month}월`,
          align: 'center',
          sortable: true,
          style: 'width: 60px',
          type: 'custom',
          // type: 'link',
          yearmonth: `${this.year}-${this.$_.padStart(month, 2, '0')}`
        })
      }
      cols.push({
        name: 'result',
        field: 'result',
        label: `${this.year}년도 결과`,
        child: resultCol
      })

      return cols;
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.fft.fireFightingIssueCheck.status.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      console.log(this.searchParam)
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.year = this.$_.clone(this.searchParam.year)

        this.grid.data = _result.data;
      },);
    },
    isFuture(props, col) {
      let colYearmonth = col.yearmonth + '-01'
      let curYearmonth = this.$comm.getThisMonth() + '-01'
      return (props.row[col.name] === '1' || !props.row[col.name]) 
        && this.$comm.moment(curYearmonth).diff(this.$comm.moment(colYearmonth), 'months') < 0
    },
    linkClick(row, col) {
      if (col.name === 'fireFightingName') {
        this.popupOptions.title = '소방설비 상세';
        this.popupOptions.param = {
          sopFireFightingId: row.sopFireFightingId
        };
        this.popupOptions.target = () => import(`${'@/pages/fft/fireFightingMasterDetail.vue'}`);
      } else {
        // this.popupOptions.title = '소방 이슈점검 상세';
        // this.popupOptions.param = {
        //   yearmonth: col.yearmonth,
        //   sopFireFightingId: row.sopFireFightingId
        // };
        // this.popupOptions.target = () => import(`${'./fireFightingIssueCheckDetail.vue'}`);
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
